import * as React from "react";

export class PortfolioComponent extends React.Component {
  public componentDidMount() {
    document.title = "Alan Haverty";
  }
  public render() {
    return (
      <div id="page-wrap">

        <div id="contact-info" className="vcard">
          <h1 className="fn">Alan Haverty</h1>

          <p>
            Email:&nbsp;
            <a className="email reverse" href="mailto:alan.websitemailto@haverty.dev">
              ved.ytrevah@nala
            </a>
          </p>
        </div>

        <div id="objective">
          <p>
            I'm a senior software engineer with years of experience in the startup world, and
            ~10 years of development experience.
          </p>
          <p>
            I've worked on a variety of projects ranging from test automation at IBM, founding a mobile
            application marketplace startup in Dublin, Ireland, to growing and managing an engineering team in a leading product agency.
          </p>
          <p>
            I enjoy architecting and managing projects, and thrive with mobile applications,
            backend, and designing/implementing systems, databases, CI, BI etc.
          </p>
        </div>

        <dd className="clear" />

        <h1>Experience</h1>
        <dd>
          <h2>
            Yonder (<a href="https://yonder.app">yonder.app</a>)
          </h2>
          <span className="experience-position">Senior Software Developer - 2022-Current</span>
          <ul>
            <li>
              Project management &amp; architecture
            </li>
          </ul>
          <h2>
            MiniCorp (<a href="https://minicorphq.com">minicorphq.com</a>)
          </h2>
          <span className="experience-position">CTO - Dublin, Ireland - 2019-2022</span>
          <ul>
            <li>
              Building &amp; managing an Engineering Team
            </li>
            <li>
              Project management
            </li>
            <li>
              Ruby on rails
            </li>
            <li>
              Android development (Kotlin &amp; Java)
            </li>
          </ul>

          <h2>
            Bamboo (<a href="https://bamboo.app">bamboo.app</a>)
          </h2>
          <span className="experience-position">Co-Founder &amp; CTO - Dublin, Ireland - 2016-2019</span>
          <ul>
            <li>
              Day-to-day CTO'ing, hiring and leading a team of 6 developers
            </li>
            <li>
              Co-Founded the company, taking a simple idea and building it into
              a strong business with tens of thousands of customers, and
              hundreds of restaurant clients
            </li>
            <li>System architecture, database dev/maintenance, CI</li>
            <li>API development, third party sourcing/implementation</li>
            <li>Data analyst, BI development, Looker implementation</li>
            <li>
              Google Adopt a startup finalists + various consumer psychology
              courses (startuponomics)
            </li>
            <li>
              Co-founder duties, raising &gt; €500,000, legals, GDPR, and all of
              the other fun startup stuff
            </li>
          </ul>

          <h2>
            IBM (Smarter Cities)
          </h2>

          <span className="experience-position">Developer - Dublin, Ireland 2014-2016</span>
          <ul>
            <li>Test automation and development</li>
            <li>Java development, Selenium, sprint work</li>
            <li>Frontend development with Dojo and API implementation</li>
          </ul>
        </dd>

        <div className="clear" />

        <dl>
          <dd className="clear" />

          <h1>Education</h1>
          <dd>
            <h2>Dublin Institute of Technology - Dublin, Ireland</h2>
            <p>
              <strong>BSc:</strong> Computer Science 2012 - 2016 (First class
              honours)
            </p>
            <li>First Class Honours, #1 graded student for the final year</li>
            <li>Class representitive for the first few years</li>
            <li>Final year project winner awarded by Ocuco</li>
            <li>
              Final year project: Business Intelligence project using
              Numenta/Nupic Machine Intelligence to predict order demand in a
              simulated environment, with a fully functional database and front
              end consumer and administrator application in ionic
            </li>
          </dd>

          <dd className="clear" />
          <dd className="clear" />

          <h1>Skills</h1>
          <dd>
            <h2>Development Team Lead</h2>
            <p>
              Structured and maintained the team's sprint workflow using a mix
              of Bitbucket and Jira for version control and sprint work.
              Implemented continuous integration for our backend, frontend, and
              mobile application development. Put a technical design process in
              place, and a customer feedback loop for the team.
            </p>
            <h2>Backend Development</h2>
            <p>
              Designing and implementing backends and APIs for consumer-facing
              native mobile applications. Experience mainly with a NodeJS
              serverless backend, with a mix of PubSub/cron scheduled tasks.
              Also implemented the Backend and Frontend version control and CI
              workflow.
            </p>
            <h2>Mobile Application Development</h2>
            <p>
              Built and maintained the consumer-facing java/android mobile
              application for Bamboo, while also building the store-facing
              java/android tablet application and alert system for Bamboo
              restaurants. In 2017, I adapted the merchant application to work
              on Clover's propriatary android-based hardware/appstore/APIs.
              Later, I continued to manage the android development team,
              managing the roadmap, implementation design and workflow (CI,
              architectures).
            </p>

            <h2>Database &amp; Business Intelligence</h2>
            <p>
              I've worked with a mix of transactional, nosql, BI including:
              MySQL, Firebase, Firestore, Big Query, Postgresql and Looker.
              Implemented a db migration development workflow for the company.
              Empowered the team with BI to maximise growth and identify where
              our problems were.
            </p>
          </dd>

          <dd className="clear" />

          <h1>Hobbies</h1>
          <dd>
            <li>Road cycling 🚴‍ (Mizen to Malin, 5 day trip)</li>
            <li>Running 🏃‍ (2019 South Dublin 10K)</li>
            <li>
              Mandolin 🎶
            </li>
            <li>
              Swimmer 🏊‍(Ex-competitive swimmer, turned water polo'er, turned
              weekend swimmer)
            </li>
            <li>
              Open-Source dabbling 💻 (Worked on some medical device applications,
              mainly android UI + a lot of commits/issues on work-related open
              source projects)
            </li>
          </dd>

          <dd className="clear" />
        </dl>
      </div>
    );
  }
}
